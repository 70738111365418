import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Button, Divider } from "@material-ui/core";
import NewTicketModal from "../NewTicketModal";

const VCardPreview = ({ contact, numbers }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const [selectedContact, setContact] = useState({
        name: "",
        number: "",
        profilePicUrl: ""
    });

    const [isContactValid, setContactValid] = useState(true);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                let contactObj = {
                    name: contact,
                    number: numbers.replace(/\D/g, ""),
                    //profilePicUrl:contact.profilePicUrl,
                    email: ""
                };
               
                const { data } = await api.post("/contacts/findorcreate", contactObj);

                if (data.alreadyExists) {
                    setContact(data.alreadyExists);
                } else {
                    setContact(contactObj);
                }
            
                if(data.invalid){
                    setContactValid(false);
                }                

            } catch (err) {
                toastError(err);
            }
        };

        const delayDebounceFn = setTimeout(fetchContacts, 1500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, numbers]);

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
        // Não redefina o selectedContact aqui
    };
  

    return (
        <>
            <div style={{ minWidth: "250px" }}>
                <NewTicketModal
                    modalOpen={newTicketModalOpen}
                    initialContact={selectedContact} // Preserve o valor aqui
                    onClose={(ticket) => {
                        handleCloseOrOpenTicket(ticket);
                    }}
                />
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <Avatar src={selectedContact.profilePicUrl} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography style={{ marginTop: "12px", marginLeft: "10px" }} variant="subtitle1" color="primary" gutterBottom>
                            {selectedContact.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>                
                        <Typography style={{ marginLeft: "10px" }} variant="body2" color="textSecondary" gutterBottom>
                            <strong>Nome:</strong> {selectedContact.name}
                        </Typography>
                        <Typography style={{ marginLeft: "10px" }} variant="body2" color="textSecondary" gutterBottom>
                            <Typography variant="body2" color="textSecondary" component="span">
                                <strong>Telefone:</strong> {selectedContact.number}
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Button
                            fullWidth
                            color="primary"
                            onClick={() => {
                                setNewTicketModalOpen(true); // Apenas abre o modal
                               
                            }}
                            disabled={!selectedContact.number || !isContactValid}
                        >
                            {isContactValid ? "Conversar (Novo Ticket)" : "CONTATO FORA DO WHATSAPP"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default VCardPreview;
